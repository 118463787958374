// import 'regenerator-runtime/runtime';

window.addEventListener('load', handleWindowLoad);

function handleWindowLoad() {
    const loader = document.querySelector('.loader');
    loader.style.opacity = 0;
    loader.style.zIndex = -1;
}

function loadAllSvgIcons(requireContext) {
    requireContext.keys()
        .forEach(requireContext);
}

loadAllSvgIcons(require.context('../img/icons/', true, /\.svg$/));

fetch('./assets/img/icons/sprite/svg-icons.svg')
    .then((res) => res.text())
    .then((data) => {
        document.getElementById('svg-icons').innerHTML = data;
    });
